import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  Content,
  ContactForm,
} from "@bluefin/components";
import { Header, List, Grid, div } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class ProductsAndServicesPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
        />
        <PageLayout
          hero={
            <Background columns={1} stackable={true} className={"about-hero"} />
          }
          subfooter={false}
          className={"about-page products-page"}
        >
          <Content className={"products-content"}>
            <Content.Markup isMarkdown={false} textAlign={"center"} width={16}>
              <Header as={"h1"}>Products & Services</Header>
            </Content.Markup>
            <Content.Markup isMarkdown={false} textAlign={"center"} width={14}>
              <p>
                At HoneyDip Fingerz, our products & services cater to
                individuals, groups and organizations looking for quality meals,
                excellent event setup and food management services. Our Menu has
                a rich assortment of food items that caters not only to African
                communities but other clients who want to explore our menu with
                special options.
              </p>
              <p>We offer the following services:</p>
              <List
                items={[
                  "Walk-in Orders",
                  "Corporate Events Catering",
                  "Drop Off Catering (Birthdays, Weddings)",
                  "On-The-Spot Catering:",
                ]}
              />
              <p>
                At your request we would grace your occasion with an OnSpot
                Serving station of our special meals such as Abula or Small
                Chops (Puff-Puff, Fried Yams, Spring Rolls, Samosas, etc.). For
                detailed information and estimates kindly provide us more
                information by filling out this form below or give us a call on:
                469.734.0568.
              </p>
            </Content.Markup>
          </Content>
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={8} textAlign={"left"}>
              <div className={"services-form"}>
                <ContactForm
                  constructMessage={({
                    name,
                    email,
                    subject,
                    phone,
                    description,
                  }) =>
                    `Someone has reached out to you via your website form: Name: ${name}, Email: ${email}, Phone: ${phone}, Subject: ${subject}, Description: ${description}`
                  }
                  fields={[
                    {
                      label: "name",
                      placeholder: "Full Name",
                      type: "input",
                      required: true,
                    },
                    {
                      label: "email",
                      placeholder: "Email",
                      type: "input",
                      inputType: "email",
                      required: true,
                    },
                    {
                      label: "phone",
                      placeholder: "Phone Number",
                      type: "input",
                      required: true,
                    },
                    {
                      label: "subject",
                      placeholder: "Subject",
                      type: "input",
                      required: true,
                    },
                    {
                      label: "description",
                      placeholder: "Description",
                      type: "textarea",
                      required: false,
                    },
                  ]}
                  buttonText={"Send"}
                  centeredHeaders={true}
                  className={""}
                  fluidButton={true}
                  header={""}
                  inline={false}
                  showLabels={true}
                  subheader={""}
                  termsCopy={""}
                  withContainer={true}
                  locationId={fishermanBusiness.primaryLocation._id}
                  businessId={fishermanBusiness._id}
                  businessType={fishermanBusiness.type}
                />
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(title: { eq: "Products And Services" }) {
      title
    }
  }
`;
